import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { formatNumber } from '../../../../core/utils';
import Poster from '../../../../components/Poster';

import { Table, Row, Col } from 'reactstrap';

class WidgetAccessProductCard extends React.Component {

  static propTypes = {
    product: PropTypes.object,
    print: PropTypes.bool,
  };

  static defaultProps = {
    print: false,
  };

  constructor(props) {
    super(props);

    this.state = {}

    this.selectProduct = this.selectProduct.bind(this);
  }

  componentDidMount() {}

  selectProduct = (e, id) => {
    e.preventDefault();
    this.props.history.push('/app/products/' + id);
  }

  render() {

    return (

      <div>

        {this.props.product.product ? (
          <>

            <h6 className="pt-1 text-center">
              {this.props.product.product.short_name ? (
                <span>{this.props.product.product.short_name}</span>
              ) : (
                this.props.product.product.name && (this.props.product.product.name.length > 40) ? (
                  <span>{this.props.product.product.name.slice(0,40)}...</span>
                ) : (
                  <span>{this.props.product.product.name}</span>
                )
              )}
              <br/>{formatNumber('$X', this.props.product.totals.control.per_ticket.face, false)} per ticket
            </h6>
            <div className="gutter-bottom-fixed-4">
              <div className="nopad nomargin">
                <div className="d-flex">
                  <div>
                    <a href="/" onClick={((e) => this.selectProduct(e, this.props.product.product.id))}>
                      <Poster id={this.props.product.product.poster_id} w={150} className="border-right img-poster" style={{width: 150}} />
                    </a>
                  </div>
                  <div className="flex-grow">

                    <Row className="p-0 m-0">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h6 className="gutter-top-fixed-2 text-muted">CONTROL</h6>
                        <Table className="fs-mini bordered nopad-bottom nomargin-bottom border p-0 m-0 bg-light">
                          <tbody className="p-1"> 
                            <tr>
                              <td width="60%">
                                <span>Access Fee Per Ticket</span>
                              </td>
                              <td width="40%">
                                <span>{formatNumber('$X.XX', this.props.product.totals.control.per_ticket.access_fees, false)}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="fw-bold">Period Access Fees</span>
                              </td>
                              <td>
                                <span className="fw-bold">{formatNumber('$X', this.props.product.totals.control.access_fees, false)}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Percent of Face</span>
                              </td>
                              <td>
                                <span>{formatNumber('X.XX%', parseFloat(this.props.product.totals.control.access_fees/this.props.product.totals.control.face), false)}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="fw-bold">Annualized Access Fees</span>
                              </td>
                              <td>
                                <span className="fw-bold">{formatNumber('$X', this.props.product.totals.control.annualized.access_fees, false)}</span>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h6 className="gutter-top-fixed-2 text-muted">VARIANT</h6>
                        <Table className="fs-mini bordered nopad-bottom nomargin-bottom border p-0 m-0 bg-light">
                          <tbody className="p-1"> 
                            <tr>
                              <td width="60%">
                                <span>Access Fee Per Ticket</span>
                              </td>
                              <td width="40%">
                                <span>{formatNumber('$X.XX', this.props.product.totals.variant.per_ticket.access_fees, false)}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Percent of Face</span>
                              </td>
                              <td>
                                <span>{formatNumber('X.XX%', parseFloat(this.props.product.totals.variant.access_fees/this.props.product.totals.variant.face), false)}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="fw-bold">Annualized Access Fees</span>
                              </td>
                              <td>
                                <span className="fw-bold">{formatNumber('$X', this.props.product.totals.variant.annualized.access_fees, false)}</span>
                              </td>
                            </tr>
                            <tr>
                              <td className="bg-white">
                                <span className="fw-bold">Net +/-</span>
                              </td>
                              <td className="bg-white">
                                <span className="fw-bold">{formatNumber('$X', this.props.product.totals.control.annualized.access_fees-this.props.product.totals.variant.annualized.access_fees, false)}</span>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>

                      
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
              
      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetAccessProductCard));
