import React, { useState } from 'react';
import { Row, Col, Button, Table } from 'reactstrap';

import { formatNumber } from '../../../../core/utils';

import Displays from '../../../../components/Displays';
import Poster from '../../../../components/Poster';

const SemCampaign = (props) => {

  const [expand, setExpand] = useState(false);

  const handleExpand = function() {
    setExpand(!expand);
    const section = document.querySelector('#block_' + props.keyword.slug);
    if(section) {
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
  }

  return (
    <div>
      <Row className={expand ? 'bg-dark text-white p-3 gutter-top-fixed-3':''} id={'block_' + props.keyword.slug}>
        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
          <Row className="">
            <Col xs={3} sm={3} md={3} lg={2} xl={2} className="d-print-none">
              <h6>
                <Button className="btn btn-default btn-xs inline-block text-inline" onClick={() => handleExpand(props.keyword.slug)}>
                  <i className={'fa-light ' + (expand ? 'fa-minus' : 'fa-plus')} />
                </Button>
              </h6>
            </Col>
            <Col xs={9} sm={9} md={9} lg={10} xl={10}>
              {props.keyword.show222 ? (
                <Row>
                  <Col xs={12} sm={3} md={3} lg={3} xl={2}>
                    {props.keyword.show && props.keyword.show.poster_id ? (
                      <Poster id={props.keyword.show.poster_id} w={75} className="img-fluid" />
                    ) : (
                      <Poster w={75} />
                    )}
                  </Col>
                  <Col xs={12} sm={9} md={9} lg={9} xl={10}>
                    <p className={'text-upper ' + (expand ? ' fw-bold' : '')}>
                      <span>{props.keyword.name.toUpperCase()}</span>
                      <p className="mt-1 text-muted">Spend: {formatNumber('$X', props.keyword.years[0].spend, false)}</p>
                    </p>
                    
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p className={'text-capitalize ' + (expand ? ' fw-bold' : '')}>
                      {props.keyword.name.toUpperCase()}
                    </p>
                    <p className="m-0 p-0 gutter-top-fixed-1 text-muted">Spend: {formatNumber('$X', props.keyword.years[0].spend, false)}</p>
                  </Col>
                </Row>
              )}
              
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
          <h5>{formatNumber('X,', props.keyword.years[0].potential_impressions, false)}</h5>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
          <h5>{formatNumber('X,', props.keyword.years[0].impressions, false)}</h5>
          <p className="m-0 p-0 gutter-top-fixed-1 text-muted">{formatNumber('X.X%', parseFloat(props.keyword.years[0].impressions/props.keyword.years[0].potential_impressions), false)}</p>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
          <h5>{formatNumber('X,', props.keyword.years[0].clicks, false)}</h5>
          <p className="m-0 p-0 gutter-top-fixed-1 text-muted">{formatNumber('X.X%', parseFloat(props.keyword.years[0].clicks/props.keyword.years[0].impressions), false)}</p>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
          <h5>&nbsp;</h5>        
          <h5>{formatNumber('X,', props.keyword.years[0].orders, false)}</h5>
          <p className="m-0 p-0 gutter-top-fixed-1 text-muted">{formatNumber('X.X%', props.keyword.years[0].conversion_percentage, false)}</p>
        </Col>
      </Row>

      {expand ? (
          <div className="w-100">
            <Row className="gutter-top-fixed-4">
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <h5 className="">Search Metrics</h5>
                <div className="gutter-top-fixed-1 bg-light border">
                  <Table className="fs-mini nopad-bottom nomargin-bottom border">
                    <thead>
                      <tr>
                          <th width="33%">Metric</th>
                          <th className="text-center" width="33%">Value</th>
                          <th className="text-center" width="33%">PY +/-</th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Impressions</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('X,', props.keyword.years[0].impressions, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.keyword.years[1] ? (
                            <h6><Displays a={props.keyword.years[0].impressions} b={props.keyword.years[1].impressions} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Click Thru Rate</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('X.X%', props.keyword.years[0].ctr, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.keyword.years[1] ? (
                            <h6><Displays a={props.keyword.years[0].ctr} b={props.keyword.years[1].ctr} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Cost Per Click</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('$X.XX', props.keyword.years[0].cpc, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.keyword.years[1] ? (
                            <h6><Displays a={props.keyword.years[0].cpc} b={props.keyword.years[1].cpc} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <h5 className="">Funnel Metrics</h5>
                <div className="gutter-top-fixed-1 bg-light border">
                <Table className="fs-mini nopad-bottom nomargin-bottom border">
                    <thead>
                      <tr>
                          <th width="33%">Metric</th>
                          <th className="text-center" width="33%">Value</th>
                          <th className="text-center" width="33%">PY +/-</th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Starts</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('X,', props.keyword.years[0].clicks, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.keyword.years[1] ? (
                            <h6><Displays a={props.keyword.years[0].clicks} b={props.keyword.years[1].clicks} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Conversion %</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('X.X%', props.keyword.years[0].conversion_percentage, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.keyword.years[1] ? (
                            <h6><Displays a={props.keyword.years[0].conversion_percentage} b={props.keyword.years[1].conversion_percentage} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Orders</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('X,', props.keyword.years[0].orders, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.keyword.years[1] ? (
                            <h6><Displays a={props.keyword.years[0].orders} b={props.keyword.years[1].orders} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                </Table>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <h5 className="">Revenue</h5>
                <div className="gutter-top-fixed-1 bg-light border">
                <Table className="fs-mini nopad-bottom nomargin-bottom border">
                    <thead>
                      <tr>
                          <th width="33%">Metric</th>
                          <th className="text-center" width="33%">Value</th>
                          <th className="text-center" width="33%">PY +/-</th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Service Fees</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('$X', props.keyword.years[0].service_fees, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.keyword.years[1] ? (
                            <h6><Displays a={props.keyword.years[0].service_fees} b={props.keyword.years[1].service_fees} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        {/* <td>
                          <h6 style={{height: 30}}>Margin<br/><span className="text-muted">Type: {props.keyword.years[0].per_order.margin_type}</span></h6>
                        </td> */}
                        {/* <td className="text-center">
                          <h6>{formatNumber('X.X%', props.keyword.years[0].per_order.margin, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.keyword.years[1] ? (
                            <h6><Displays a={props.keyword.years[0].per_order.margin} b={props.keyword.years[1].per_order.margin} format="percent" fixed={2} /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td> */}
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Service Fees Less Fixed Cost</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('$X', props.keyword.years[0].net, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.keyword.years[1] ? (
                            <h6><Displays a={props.keyword.years[0].net} b={props.keyword.years[1].net} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Service Fees, Less Fixed Cost, Less Search Spend</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('$X', props.keyword.years[0].net_net, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.keyword.years[1] ? (
                            <h6><Displays a={props.keyword.years[0].net_net} b={props.keyword.years[1].net_net} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
      ) : null}

        <hr/>

    </div>
  );
};

export default SemCampaign;