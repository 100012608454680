import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Table,
  Button
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import DashboardCard from './cards/Dashboard.js';
import ProductCard from './cards/Known.js';
import { surl, scurl } from '../../../core/utils.js';
import { filterUpdated, changeSalesMetric, changeAffiliateSalesMetric, subSort, displaySalesMetric } from '../../../core/utils';
import More from '../../../components/More';

import Slider from "react-slick";

import moment from 'moment';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#transparent" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#transparent" }}
      onClick={onClick}
    />
  );
}
class WidgetShows extends React.Component {

  static propTypes = {
    engagement: PropTypes.object,
    print: PropTypes.bool,
    fixed_years: PropTypes.number,
    title: PropTypes.bool,
    exclude_zero: PropTypes.bool,
    perf_date: PropTypes.bool,
    className: PropTypes.string,
    sales_metric: PropTypes.string,
    affiliate: PropTypes.bool,
    display: PropTypes.number,
    broadway: PropTypes.bool,
  };

  static defaultProps = {
    engagement: null,
    print: false,
    fixed_years: 3,
    title: true,
    exclude_zero: false,
    perf_date: false,
    className: '',
    sales_metric: 'face',
    affiliate: true,
    display: 12,
    broadway: false
  };

  constructor(props) {
    super(props);

    var display = 12;
    if(this.props.display) {
      display = this.props.display;
    };

    this.state = {
      isLoaded: false,
      display: display,
      perf_date: this.props.perf_date,
      sales_metric: this.props.sales_metric,
    }

    this._isMounted = false;
    this.loadSales = this.loadSales.bind(this);
    this.loadUrl = this.loadUrl.bind(this);
    this.viewAll = this.viewAll.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadSales();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'SHOWS WIDGET')) {
      this.loadSales();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadSales() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/transactions/shows',
        type: 'universal'
      };

      opts.perf_date = this.state.perf_date;

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(this.props.broadway) {
              var broadway_only = [];
              for(var engagement of results.shows.dates[0].sales) {
                if(engagement.product && engagement.product.broadway) {
                  broadway_only.push(engagement);
                }
              }
              results.shows.dates[0].sales = broadway_only;
            }

            this.setState({
              isLoaded: true,
              results: results,
            }, function() {
              this.loadUrl();
            });
          }
        } else {
          console.log('handled unmount');
        } 
      }.bind(this));
    });  
  }

  loadUrl() {

    var opts = {
      path: '/transactions/export/products',
      type: 'universal'
    };

    opts.perf_date = this.state.perf_date;

    var url = surl(opts);  
    if(this._isMounted) {
      this.setState({
        url: url
      });
    } else {
      console.log('handled unmount');
    }
  }

  viewAll = () => {
      
    this.setState({
      display: 100
    });

  }

  changePerfTrans = () => { 
    this.setState({
      perf_date: !this.state.perf_date
    }, function() {
      this.loadSales();
    });
  }

  changeSalesMetric = () => { 
    var change_display = '';
    if(this.props.affiliate) {
      change_display = changeAffiliateSalesMetric(this.state.sales_metric);
    } else {
      change_display = changeSalesMetric(this.state.sales_metric);
    }

    subSort(change_display,this.state.results.shows.dates[0].sales,1);

    this.setState({
      sales_metric: change_display,
      results: this.state.results
    });
  };

  selectProduct = (e, id) => {
    e.preventDefault();
    this.props.history.push('/app/products/' + id);
  }

  render() {

    var title = <h5>Loading</h5>;
    this.props.dashboard ? (
      title = <h5>
        <span>Top Shows</span>
        <small className="text-muted pl-2">Displaying <button className="header-link" onClick={() => this.changeSalesMetric()}>{displaySalesMetric(this.state.sales_metric)}</button>, by <button className="header-link" onClick={() => this.changePerfTrans()}>{this.state.perf_date ? (<span>Performance Date</span>) : (<span>Transaction Date</span>)}</button>.</small>
      </h5>
    ) : (
      this.state.isLoaded ? (
        title = <h5><span className="text-danger">Top Sellers</span>&nbsp;&nbsp;<small className="text-muted">By <button className="header-link" onClick={() => this.changePerfTrans()}>{this.state.perf_date ? (<span>Performance Date</span>) : (<span>Transaction Date</span>)}</button>. From {moment(this.state.results.years[0].dates.start).format('dddd, MMMM Do YYYY')} through {moment(this.state.results.years[0].dates.end).format('dddd, MMMM Do YYYY')}.</small></h5>
      ) : (
        title = <h5><span className="text-danger">Top Sellers</span>&nbsp;&nbsp;<small className="text-muted">By show, by <button className="header-link" onClick={() => this.changePerfTrans()}>{this.state.perf_date ? (<span>Performance Date</span>) : (<span>Transaction Date</span>)}</button>.{!this.props.print ? (<span> Per ticket averages below each metric.</span>) : null }</small></h5>
      )
    )

    var settings = {
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      infinite: false,
      dots: false,
      slidesToShow: 12,
      slidesToScroll: 3,
      speed: 100,
      lazyLoad: true,
      responsive: [
        {
          breakpoint: 10000,
          settings: {
            slidesToShow: 12,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 10,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    };

    return (
      this.props.dashboard ? (

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <div className="gutter-left-fixed-2 gutter-right-fixed-2 gutter-bottom-fixed-2">
              <Slider {...settings}>
              {this.state.results.years[0].shows.map((product, i) =>
                  product.product && (product.face > 1) ? (
                    <DashboardCard key={i} print={this.props.print} product={product} sales_metric={this.state.sales_metric} />
                  ) : null
                )}
              </Slider>
            </div>
          ) : null}

          <More url="/app/transactions/1" />
          
        </Widget>

      ) : (

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div>
              {this.props.print ? (

                  <div className="gutter-top-fixed-3">
                    <Table>
                      <thead>
                        <tr>
                          <th width="40%">Show</th>
                          <th width="30%" className="text-center">Face Value</th>
                          <th width="30%" className="text-center">Average<br/>Price</th>
                        </tr>
                      </thead>
                      <tbody> 
                        {this.state.results.years[0].shows.map((product, i) =>
                          (i < this.state.display) && product.product && (product.face > 1) ? (
                            <tr key={i}>
                              <td className="text-left">
                                <span className="text-capitalize fs-mini">{product.product.short_name || product.product.name }</span>
                              </td>
                              <td className="text-center">
                                <span className="text-capitalize fs-mini">${parseInt(product.face).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                              </td>
                              <td className="text-center">
                                <span className="text-capitalize fs-mini">${parseFloat(product.face/product.qty).toFixed(2)}</span>
                              </td>
                            </tr>
                          ) : null
                        )}
                      </tbody>
                    </Table>
                  </div>
                  
                ) : (
                  <div className="gutter-top-fixed-4">
                    <Row>
                        {this.state.results.years[0].shows.map((product, i) =>
                          i < this.state.display ? (
                            <Col xs={12} sm={12} md={12} lg={6} xl={4} key={i} >
                              <ProductCard print={this.props.print} product={product} sales_metric={this.state.sales_metric} />
                            </Col>
                          ) : null
                        )}
                    </Row>
                    {this.state.display < this.state.results.years[0].shows.length ? (
                      <div className="text-center">
                        <Button className="btn-success" onClick={() => this.viewAll()}><i className="fa-light fa-arrows-up-down fa-lg gutter-right-fixed-1" />View All</Button>
                      </div>
                      ) : null}
                  </div>
                )}
            </div>

          ) : null}

        </Widget>
      )
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetShows));
