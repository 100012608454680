import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';

import Widget from '../../../components/Widget/Widget.js';
import Loading from '../../../components/Loading/Loading.js';
import { filterUpdated, surl } from '../../../core/utils.js';
import { Row, Col, Button, Table } from 'reactstrap';

class WidgetKnownExcel extends React.Component {

  static propTypes = {
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
    }

    this._isMounted = false;
    this.setReports = this.setReports.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setReports();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'FILTER COMPONENT UPDATED')) {
      this.setReports();
    }
  }

  setReports() {

    let reports = [];

    //SEM
    let sem = {
      title: 'Campaign & Transaction Data Merge',
      description: 'Data behind the Genenric and Show campaign tabs',
      type: 'Endpoint,Excel',
      last_updated: DateTime.fromISO('2024-11-13').toLocaleString(DateTime.DATE_FULL),
      requires: '',
      status: true,
      status_message: 'Ready',
      path: surl({path: '/v2/excel/sem', filter: 'universal'}),
      endpoint: surl({path: '/v2/sem/campaigns', filter: 'universal', years: 2 })
    };
    reports.push(sem);

    //TRANSACTIONS
    let transactions = {
      title: 'Transaction Detail',
      description: 'Complete transactions for supplied date range. Includes only Broadway.com orders (no affiliates) and excludes all refunds.',
      type: 'Excel',
      last_updated: DateTime.fromISO('2024-11-13').toLocaleString(DateTime.DATE_FULL),
      requires: '',
      status: true,
      status_message: 'Ready',
      path: surl({path: '/v2/excel/transactions', filter: 'universal'}),
      endpoint: surl({path: '/v2/transactions/detail', filter: 'universal'})
    };
    reports.push(transactions);

    this.setState({
      ...this.state,
      reports: reports,
      isLoaded: true
    });
  };

  render() {

    return (

      <div>

        <Row className="gutter-top-fixed-2 gutter-bottom-fixed-2">
            <Col xs={12} sm={12} md={12} lg={12}>
              <Widget title={<h5><span className="text-danger">Data Access</span> <small className="text-muted"></small></h5>}>

                <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

                {this.state.isLoaded ? (
                  <div className="gutter-top-fixed-1">

                    <Table className="fs-mini nopad-bottom nomargin-bottom border">
                      <thead className="bg-gray">
                        <tr>
                            <th width="60%"><h6 className="gutter-top-fixed-1">Dataset</h6></th>
                            <th className="text-center" width="20%">&nbsp;</th>
                            <th className="text-center" width="20%">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody> 

                      {this.state.reports.map((report, i) =>
                        <tr key={i}>
                          <td>
                            <h5 className="gutter-top-fixed-1">{report.title}</h5>
                            <p>{report.description}</p>
                          </td>
                          <td className="text-center">
                            <div className="gutter-top-fixed-1">
                              <a className="w-100" href={report.endpoint} target="_blank" rel="noopener noreferrer">
                                <Button className="btn-default w-100" disabled={!report.status}>
                                  <h6 className="gutter-top-fixed-1"><i className="fa-light fa-rss" /> API Endpoint</h6>
                                </Button>
                              </a>
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="gutter-top-fixed-1">
                              <a className="w-100" href={report.path} target="_blank" rel="noopener noreferrer">
                                <Button className="btn-default w-100" disabled={!report.status}>
                                  <h6 className="gutter-top-fixed-1"><i className="fa-light fa-download" /> Download</h6>
                                </Button>
                              </a>
                            </div>
                          </td>
                        </tr>
                      )}

                        
                      </tbody>
                    </Table>

                  </div>
                ) : null}

              </Widget>
          </Col>
        </Row>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetKnownExcel));
