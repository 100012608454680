import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Button,
  Table,
  Input
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import ProductCard from './cards/Product.js';
import { scurl, formatNumber } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';

import Slider from 'rc-slider';
import Select from 'react-select';

class WidgetAccessAnalyzer extends React.Component {

  static propTypes = {
    engagement: PropTypes.object,
    print: PropTypes.bool,
    fixed_years: PropTypes.number,
    title: PropTypes.bool,
    exclude_zero: PropTypes.bool,
    perf_date: PropTypes.bool,
    className: PropTypes.string,
    sales_metric: PropTypes.string,
    affiliate: PropTypes.bool,
    display: PropTypes.number,
    broadway: PropTypes.bool,
  };

  static defaultProps = {
    engagement: null,
    print: false,
    fixed_years: 3,
    title: true,
    exclude_zero: false,
    perf_date: false,
    className: '',
    sales_metric: 'face',
    affiliate: true,
    display: 12,
    broadway: false
  };

  constructor(props) {
    super(props);

    var display = 12;
    if(this.props.display) {
      display = this.props.display;
    };

    let access_types = [
      { value: 'percent', label: 'Percentage' },
      { value: 'fixed', label: 'Fixed' },
      { value: 'threshold', label: 'Thresholds' }
    ];

    let threshold = {
      ranges: [
        [0,0],
        [0,0],
        [0,0],
        [0,0]
      ],
      mins: [
        0,
        0,
        0,
        0
      ],
      prices: [
        {price: 0},
        {price: 0},
        {price: 0},
        {price: 0},
      ],
      round: {
        down: 0.0,
        up: 0.01
      }
    }

    this.state = {
      isLoaded: false,
      display: display,
      perf_date: this.props.perf_date,
      access_types: {
        options: access_types,
        selected: null //access_types[2]
      },
      threshold: threshold,
      analyzer: {
        percent: {
          value: 10
        },
        fixed: {
          value: 14
        },
        thresholds: []
      }
    }

    this._isMounted = false;
    this.analyze = this.analyze.bind(this);
    this.viewAll = this.viewAll.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.analyze();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'SHOWS WIDGET')) {
      this.analyze();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  analyze() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/transactions/access',
        type: 'universal',
        // refunds: '0',
        // limit_sources: 'BROADWAY.COM'
      };

      opts.perf_date = this.state.perf_date;

      if(this.state.access_types.selected) {
        switch(this.state.access_types.selected.value) {
          case 'percent':
            opts.access_fee_type = 'percent';
            opts.access_fee_value = parseFloat(this.state.analyzer.percent.value/100);
            break;
          case 'fixed':
            opts.access_fee_type = 'fixed';
            opts.access_fee_value = this.state.analyzer.fixed.value;
            break;
          case 'threshold':
            opts.access_fee_type = 'threshold';
            opts.access_fee_value = this.thresholdString();
            break;
          default:
            break;
        }
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              ...this.state,
              isLoaded: true,
              results: results,
            });
          }
        } else {
          console.log('handled unmount');
        } 
      }.bind(this));
    });  
  }

  viewAll = () => {
      
    this.setState({
      display: 100
    });

  }

  changePerfTrans = () => { 
    this.setState({
      perf_date: !this.state.perf_date
    }, function() {
      this.analyze();
    });
  }

  selectProduct = (e, id) => {
    e.preventDefault();
    this.props.history.push('/app/products/' + id);
  }

  percentChange = (v) => {
    this.setState({
      analyzer: {
        ...this.state.analyzer,
        percent: {
          value: v
        }
      }
    });
  };

  fixedChange = (v) => {
    this.setState({
      analyzer: {
        ...this.state.analyzer,
        fixed: {
          value: v
        }
      }
    });
  };

  rangeChange0 = (v) => {
    this.setState({
      threshold: {
        ...this.state.threshold,
        ranges: [
          [v[0],parseFloat(v[1]-this.state.threshold.round.down)],
          [v[1]+this.state.threshold.round.up,1000],
          [this.state.threshold.ranges[2][0],this.state.threshold.ranges[2][1]],
          [this.state.threshold.ranges[3][0],this.state.threshold.ranges[3][1]]
        ],
        mins: [
          this.state.threshold.mins[0],
          v[1]+this.state.threshold.round.up,
          this.state.threshold.mins[2],
          this.state.threshold.mins[3]
        ]
      }
    }, function() {
      //this.thresholdString();
    });
  };

  rangeChange1 = (v) => {
    this.setState({
      threshold: {
        ...this.state.threshold,
        ranges: [
          [this.state.threshold.ranges[0][0],this.state.threshold.ranges[0][1]],
          [v[0],parseFloat(v[1]-this.state.threshold.round.down)],
          [v[1]+this.state.threshold.round.up,1000],
          [this.state.threshold.ranges[3][0],this.state.threshold.ranges[3][1]]
        ],
        mins: [
          this.state.threshold.mins[0],
          this.state.threshold.mins[1],
          v[1],
          this.state.threshold.mins[3]
        ]
      }
    }, function() {
      //this.thresholdString();
    });
  };

  rangeChange2 = (v) => {
    this.setState({
      threshold: {
        ...this.state.threshold,
        ranges: [
          [this.state.threshold.ranges[0][0],this.state.threshold.ranges[0][1]],
          [this.state.threshold.ranges[1][0],this.state.threshold.ranges[1][1]],
          [v[0],parseFloat(v[1]-this.state.threshold.round.down)],
          [v[1]+this.state.threshold.round.up,1000],
        ],
        mins: [
          this.state.threshold.mins[0],
          this.state.threshold.mins[1],
          this.state.threshold.mins[2],
          v[1]
        ]
      }
    }, function() {
      //this.thresholdString();
    });
  };

  rangeChange3 = (v) => {
    this.setState({
      threshold: {
        ...this.state.threshold,
        ranges: [
          [this.state.threshold.ranges[0][0],this.state.threshold.ranges[0][1]],
          [this.state.threshold.ranges[1][0],this.state.threshold.ranges[1][1]],
          [this.state.threshold.ranges[2][0],this.state.threshold.ranges[2][1]],
          [v[0],v[1]],
        ]
      }
    }, function() {
      //this.thresholdString();
    });
  };

  handleTypeChange = selectedOption => {

    var hasChanged = true;

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      access_types: {
        ...this.state.access_types,
        selected: selectedOption
      }
    });
  };

  thresholdString = () => {
    
    let str = '';

    for(var [i,block] of this.state.threshold.ranges.entries()) {
      if((block[1] > 0) && (block[0] !== block[1])) {
        if(str.length > 0) {
          str += ',';
        }
        str += block[0] + '-' + block[1] + ':' + this.state.threshold.prices[i].price;
      }
    }

    return str;
  };

  thresholdPriceUpdate = (v) => {
    
    let id = v.target.id.split('_')[1];
    let value = v.target.value;

    let prices = [...this.state.threshold.prices];
    let price = {...this.state.threshold.prices[id]};
    price.price = value;
    prices[id] = price;
    this.setState({
      threshold: {
        ...this.state.threshold,
        prices: prices
      }
    }, function() {
      // console.log(id + ' : ' + value);
      // console.log(this.state.threshold);
    });
  };

  render() {

    var title = <h5>Loading</h5>;
    title = <h5><span className="text-danger">Access Fee Analyzer</span>&nbsp;&nbsp;<small className="text-muted">By show, by <button className="header-link" onClick={() => this.changePerfTrans()}>{this.state.perf_date ? (<span>Performance Date</span>) : (<span>Transaction Date</span>)}</button>.{!this.props.print ? (<span> Per ticket averages below each metric.</span>) : null }</small></h5>

    return (
      <Widget title={title}>

        {this.state ? (
        
          <div>

            <Row className="gutter-top-fixed-3 gutter-bottom-fixed-1">
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <h6 className="text-muted gutter-top-fixed-2">SET VARIANT</h6>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2} className="text-right">
                <Button className="btn-success btn-sm" onClick={() => this.analyze()}>
                  <i className="fa-light fa-bolt" />
                </Button>
              </Col>
            </Row>

            <hr className="p-0 m-0" />
            <Row className="p-0 m-0">
              <Col xs={12} sm={12} md={3} lg={3} xl={3} className="mb-3 bg-light">
                <div className="gutter-top-fixed-2 gutter-bottom-fixed-1">
                  <h6 className="pt-1 text-muted">TYPE</h6>
                  <Select
                    value={this.state.access_types.selected}
                    onChange={this.handleTypeChange}
                    options={this.state.access_types.options}
                    placeholder='Select Access Fee Type'
                    isMulti={false}
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={9} lg={9} xl={9} className="mb-3">
                {this.state.access_types.selected ? (
                  <div className="gutter-top-fixed-2 gutter-bottom-fixed-1">
                    {this.state.access_types.selected.value === 'percent' ? (
                        <>
                          <h6 className="pt-1 text-muted">PERCENT</h6>
                          <Slider
                            defaultValue={this.state.analyzer.percent.value}
                            min={5}
                            max={15}
                            onChange={this.percentChange}
                            step={0.1}
                          />
                          <p>{formatNumber('X.X%', parseFloat(this.state.analyzer.percent.value/100), false)}</p>
                        </>
                    ) : (
                      this.state.access_types.selected.value === 'fixed' ? (
                        <>
                          <h6 className="pt-1 text-muted">FIXED</h6>
                          <Slider
                            defaultValue={this.state.analyzer.fixed.value}
                            min={0}
                            max={20}
                            onChange={this.fixedChange}
                            step={0.25}
                          />
                          <p>{formatNumber('$X.XX', parseFloat(this.state.analyzer.fixed.value), false)}</p>
                        </>
                      ) : (
                        this.state.access_types.selected.value === 'threshold' ? (
                          <>
                            <Row className="p-0 m-0">
                              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <h6 className="pt-1 text-muted">PRICE RANGE</h6>
                                <Slider
                                  range
                                  min={this.state.threshold.mins[0]}
                                  max={1000}
                                  allowCross={false}
                                  pushable={true}
                                  onChange={this.rangeChange0}
                                />
                                <p>{formatNumber('$X.XX', parseFloat(this.state.threshold.ranges[0][0]), false)} - {formatNumber('$X.XX', parseFloat(this.state.threshold.ranges[0][1]), false)}</p>
                              </Col>
                              <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                                <h6 className="pt-1 text-muted">FEE</h6>
                                <Input width={5} id="threshold_0" onChange={this.thresholdPriceUpdate} />
                              </Col>
                            </Row>

                            <hr />

                            <Row className="p-0 m-0">
                              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <h6 className="pt-1 text-muted">PRICE RANGE</h6>
                                <Slider
                                  range
                                  min={this.state.threshold.mins[1]}
                                  max={1000}
                                  allowCross={false}
                                  pushable={true}
                                  onChange={this.rangeChange1}
                                />
                                <p>{formatNumber('$X.XX', parseFloat(this.state.threshold.ranges[1][0]), false)} - {formatNumber('$X.XX', parseFloat(this.state.threshold.ranges[1][1]), false)}</p>
                              </Col>
                              <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                                <h6 className="pt-1 text-muted">FEE</h6>
                                <Input width={5} id="threshold_1" onChange={this.thresholdPriceUpdate} />
                              </Col>
                            </Row>

                            <hr />

                            <Row className="p-0 m-0">
                              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <h6 className="pt-1 text-muted">PRICE RANGE</h6>
                                <Slider
                                  range
                                  min={this.state.threshold.mins[2]}
                                  max={1000}
                                  allowCross={false}
                                  pushable={true}
                                  onChange={this.rangeChange2}
                                />
                                <p>{formatNumber('$X.XX', parseFloat(this.state.threshold.ranges[2][0]), false)} - {formatNumber('$X.XX', parseFloat(this.state.threshold.ranges[2][1]), false)}</p>
                              </Col>
                              <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                                <h6 className="pt-1 text-muted">FEE</h6>
                                <Input width={5} id="threshold_fee_0" placeholder="" />
                              </Col>
                            </Row>

                            <hr />

                            <Row className="p-0 m-0">
                              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <h6 className="pt-1 text-muted">PRICE RANGE</h6>
                                <Slider
                                  range
                                  min={this.state.threshold.mins[3]}
                                  max={1000}
                                  allowCross={false}
                                  pushable={true}
                                  onChange={this.rangeChange3}
                                />
                                <p>{formatNumber('$X.XX', parseFloat(this.state.threshold.ranges[3][0]), false)} - {formatNumber('$X.XX', parseFloat(this.state.threshold.ranges[3][1]), false)}</p>
                              </Col>
                              <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                                <h6 className="pt-1 text-muted">FEE</h6>
                                <Input width={5} id="threshold_fee_0" placeholder="" />
                              </Col>
                            </Row>
                            <hr />

                          </>
                        ) : null
                      )
                    )}
                  </div>
                ) : null}
              </Col>
            </Row>
            <hr className="p-0 m-0" />

            <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

            {this.state.isLoaded ? (
            <>
              <Row className="gutter-top-fixed-4 gutter-bottom-fixed-4">
                <Col xs={1} sm={1} md={2} lg={2} xl={2}></Col>
                <Col xs={10} sm={10} md={4} lg={4} xl={4}>
                  <h6 className="pt-1 text-muted">CONTROL</h6>
                  <Table className="fs-mini bordered nopad-bottom nomargin-bottom border p-0 m-0 bg-light">
                    <tbody className="p-1"> 
                      <tr>
                        <td width="60%">
                          <span>Access Fee Per Ticket</span>
                        </td>
                        <td width="40%">
                          <span>{formatNumber('$X.XX', this.state.results.totals.control.per_ticket.access_fees, false)}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Period Access Fees</span>
                        </td>
                        <td>
                          <span>{formatNumber('$X', this.state.results.totals.control.access_fees, false)}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Percent of Face</span>
                        </td>
                        <td>
                          <span>{formatNumber('X.XX%', parseFloat(this.state.results.totals.control.access_fees/this.state.results.totals.control.face), false)}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="fw-bold">Annualized Access Fees</span>
                        </td>
                        <td>
                          <span className="fw-bold">{formatNumber('$X', this.state.results.totals.control.annualized.access_fees, false)}</span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col xs={10} sm={10} md={4} lg={4} xl={4}>
                  <h6 className="pt-1 text-muted">VARIANT</h6>
                  <Table className="fs-mini bordered nopad-bottom nomargin-bottom border p-0 m-0 bg-light">
                    <tbody className="p-1"> 
                      <tr>
                        <td width="60%">
                          <span>Access Fee Per Ticket</span>
                        </td>
                        <td width="40%">
                          <span>{formatNumber('$X.XX', this.state.results.totals.variant.per_ticket.access_fees, false)}</span>
                        </td>
                      </tr>
                      {/* <tr>
                        <td>
                          <span>Total Access Fees</span>
                        </td>
                        <td>
                          <span>{formatNumber('$X', this.state.results.totals.variant.access_fees, false)}</span>
                        </td>
                      </tr> */}
                      <tr>
                        <td>
                          <span>Percent of Face</span>
                        </td>
                        <td>
                          <span>{formatNumber('X.XX%', parseFloat(this.state.results.totals.variant.access_fees/this.state.results.totals.variant.face), false)}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="fw-bold">Annualized Access Fees</span>
                        </td>
                        <td>
                          <span className="fw-bold">{formatNumber('$X', this.state.results.totals.variant.annualized.access_fees, false)}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="bg-white">
                          <span className="fw-bold">Net +/-</span>
                        </td>
                        <td className="bg-white">
                          <span className="fw-bold">{formatNumber('$X', this.state.results.totals.control.annualized.access_fees-this.state.results.totals.variant.annualized.access_fees, false)}</span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <hr />

              <Row className="gutter-top-fixed-4">
                  {this.state.results.shows.map((product, i) =>
                    i < this.state.display ? (
                      <Col xs={12} sm={12} md={12} lg={12} xl={6} key={i} >
                        <>
                          <ProductCard print={this.props.print} product={product} sales_metric={this.state.sales_metric} />
                          <hr />
                        </>
                      </Col>
                    ) : null
                  )}
              </Row>
              {this.state.display < this.state.results.shows.length ? (
                <div className="text-center">
                  <Button className="btn-success" onClick={() => this.viewAll()}><i className="fa-light fa-arrows-up-down fa-lg gutter-right-fixed-1" />View All</Button>
                </div>
              ) : null}
            </>
            ) : null}
          </div>

        ) : null}

      </Widget>
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetAccessAnalyzer));
