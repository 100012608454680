import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row, 
  Col
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import More from '../../../components/More';

import Select from 'react-select';

import { scurl, checkExists } from '../../../core/utils.js';
import { filterUpdated, defaultTimespanV2, datespan, dateRangeDisplay, displaySalesMetric, changeSalesMetric, changeAffiliateSalesMetric } from '../../../core/utils';

import DataPointCard from '../../cards/DataPoint.js';
import ChartLegend from '../../cards/Legend.js';

import UBar from '../../universal/Bar';

import DashCam from '../../universal/DashCam/DashCam.js';

class WidgetSalesTimespans extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    dashboard: PropTypes.bool,
    timespan: PropTypes.string,
    fixed_years: PropTypes.number,
    refunds: PropTypes.bool,
    limit_sources: PropTypes.string,
    lookback: PropTypes.number,
    product_id: PropTypes.number,
    perf_date: PropTypes.bool,
    sales_metric: PropTypes.string,
    user: PropTypes.object
  };

  static defaultProps = {
    print: false,
    dashboard: false,
    timespan: 'days',
    fixed_years: 6,
    lookback: 30,
    perf_date: false,
    sales_metric: 'face'
  };

  constructor(props) {
    super(props);

    let dorw = defaultTimespanV2(this.props.filter);
    let drd = dateRangeDisplay(this.props.filter.dates, 'MMMM dd, yyyy');
    
    let col_lg = 12;
    if(this.props.dashboard) {
      col_lg = 12;
    }

    let perf_trans_options = [
      { value: 0, label: 'Trans Date' },
      { value: 1, label: 'Perf Date' },
    ]

    let perf_trans_selected = perf_trans_options[0];
    if(this.props.perf_date) {
      perf_trans_selected = perf_trans_options[1];
    }


    let fixed_years = this.props.fixed_years;
    if(checkExists(this.props.user.permissions.fixed_years)) {
      fixed_years = this.props.user.permissions.fixed_years;
    }

    let refunds = this.props.refunds;
    if(checkExists(this.props.user.permissions.refunds)) {
      if(this.props.user.permissions.refunds) {
        refunds = this.props.user.permissions.refunds
      }
    }

    this.state = {
      isLoaded: false,
      isError: false,
      perf_date: this.props.perf_date,
      chart_timespan: dorw,
      fixed_years: fixed_years,
      refunds: refunds,
      print: this.props.print,
      dateRangeDisplay: drd,
      col_lg: col_lg,
      sales_metric: this.props.sales_metric,
      format: '$XM',
      perf_trans_options: perf_trans_options,
      perf_trans_selected: perf_trans_selected
    }

    this._isMounted = false;
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'SALES TIMESPANS WIDGET')) {
      this.setDateRange();
      this.loadData();
    } else {
      if(prevProps.skai_filter !== this.props.skai_filter) {
        this.loadData();
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadData() {

    var lookback = datespan(this.props.filter);
    if(lookback < 7) {
      //lookback = 7;
    }
    this.setState({
      isLoaded: false,
      results: [],
      lookback: parseInt(lookback)
    }, function() {

      var opts = {
        path: '/v2/transactions/timespans',
        type: 'universal',
        years: this.state.fixed_years,
        lookback: this.state.lookback
      };

      if(this.state.perf_date) {
        opts.perf_date = true;
      }

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      if(checkExists(this.state.refunds)) {
        if(!this.state.refunds) {
          opts.refunds = '0';
        }
      } else {
        if(checkExists(this.props.user.permissions.refunds)) {
          if(!this.props.user.permissions.refunds) {
            opts.refunds = '0';
          }
        }
      }

      if(checkExists(this.props.limit_sources)) {
          opts.limit_sources = this.props.limit_sources;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {
              this.setState({
                isLoaded: true,
                results: results,
              }, function() {
                this.setDashCam();
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  changeChartTimespan = () => { 
    var change_display = this.state.chart_timespan;
    if(change_display === 'weeks') {
      change_display = 'days';
    } else {
      change_display = 'weeks';
    }
    this.setState({
      chart_timespan: change_display
    });
  }

  changePerfTrans = (event) => { 
    let perf_date = false;
    let selected = this.state.perf_trans_options[0];
    if(event.value === 1) {
      selected = this.state.perf_trans_options[1];
      perf_date = true;
    }
    this.setState({
      perf_trans_selected: selected,
      perf_date: perf_date
    }, function() {
      this.loadData()
    });
  };

  changeSalesMetric = () => { 
    var change_display = '';
    if(this.props.affiliate) {
      change_display = changeAffiliateSalesMetric(this.state.sales_metric);
    } else {
      change_display = changeSalesMetric(this.state.sales_metric);
    }

    let format = 'X';
    console.log(change_display);
    switch(change_display) {
      case 'orders':
        format = 'X,';
        break;
      case 'qty':
        format = 'XM';
        break;
      case 'face':
        format = '$XM';
        break;
      case 'fees':
        format = '$XM';
        break;
      case 'net_net':
        format = '$XM';
        break;
      case 'access_fees':
        format = '$XM';
        break;
      case 'affiliate_commission':
        format = '$XM';
        break;
      default:
        break;
    }

    this.setState({
      sales_metric: change_display,
      format: format
    }, function() {
      this.setDashCam()
    });
  };

  setDashCam() {

    let headlineFormat = '$X';
    if(this.state.sales_metric === 'qty') {
      headlineFormat = 'X,';
    }

    let dashCamData = {
      results: this.state.results,
      headline: {
        title: displaySalesMetric(this.state.sales_metric),
        value: this.state.results.totals[0][this.state.sales_metric],
        format: headlineFormat,
        yoy: parseFloat((this.state.results.totals[0][this.state.sales_metric]-this.state.results.totals[1][this.state.sales_metric])/this.state.results.totals[1][this.state.sales_metric])
      },
      panels: [
        {
          title: 'Avg Face',
          value: this.state.results.totals[0].average_price,
          format: '$X.XX',
          yoy: parseFloat((this.state.results.totals[0].average_price-this.state.results.totals[1].average_price)/this.state.results.totals[1].average_price)
        },
        {
          title: 'Margin',
          value: this.state.results.totals[0].margin,
          format: 'X.X%',
          yoy: parseFloat((this.state.results.totals[0].margin-this.state.results.totals[1].margin)/this.state.results.totals[1].margin)
        },
        {
          title: 'Avg All-In',
          value: this.state.results.totals[0].average_all_in_price,
          format: '$X.XX',
          yoy: parseFloat((this.state.results.totals[0].average_all_in_price-this.state.results.totals[1].average_all_in_price)/this.state.results.totals[1].average_all_in_price)
        },
        {
          title: 'Avg Access',
          value: this.state.results.totals[0].access_fees_per_ticket,
          format: '$X.XX',
          yoy: parseFloat((this.state.results.totals[0].access_fees_per_ticket-this.state.results.totals[1].access_fees_per_ticket)/this.state.results.totals[1].access_fees_per_ticket)
        },
        {
          title: 'Fixed Cost',
          value: this.state.results.totals[0].fixed_cost_per_ticket,
          format: '$X.XX',
          yoy: parseFloat((this.state.results.totals[0].fixed_cost_per_ticket-this.state.results.totals[1].fixed_cost_per_ticket)/this.state.results.totals[1].fixed_cost_per_ticket)
        },
        {
          title: 'Fixed Margin',
          value: this.state.results.totals[0].fixed_margin,
          format: 'X.X%',
          yoy: parseFloat((this.state.results.totals[0].fixed_margin-this.state.results.totals[1].fixed_margin)/this.state.results.totals[1].fixed_margin)
        }
      ]
    };

    this.setState({
      ...this.state,
      dashCamData: dashCamData
    });
  };

  setDateRange() {
    let drd = dateRangeDisplay(this.props.filter.dates, 'MMMM dd, yyyy');
    let dorw = defaultTimespanV2(this.props.filter);

    this.setState({
      ...this.state,
      dateRangeDisplay: drd,
      chart_timespan: dorw
    });
  }

  render() {

    let changePT = (event) => { 
      this.changePerfTrans(event);
    };

    return (

      this.props.dashboard ? (

        <Widget
          className="mb-0 h-100"
          bodyClass={`mt p-0`}
          title={
            <div className="d-flex justify-content-between flex-wrap">
              <h5 className="pt-1"><span>Transactions</span><small className="text-muted pl-2"><button className="header-link" onClick={() => this.changeSalesMetric()}>{displaySalesMetric(this.state.sales_metric)}</button> vs prior year(s)</small></h5>
              {this.state ? (
                <Select
                  value={this.state.perf_trans_selected}
                  onChange={(event) => changePT(event) }
                  options={this.state.perf_trans_options}
                  placeholder='Filter results...'
                  isMulti={false}
                  className="form-control-sm p-0 m-0"
                /> 
              ) : null}
            </div>
          }
        >

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <Row>
              <Col xs={12} md={6} lg={6} xl={6} className="border-right">
                <UBar
                  data={this.state.results}
                  print={this.props.print}
                  labels={false}
                  grid={false}
                  field={this.state.sales_metric}
                  format={this.state.format}
                />
                <small className="text-muted pt-1 pl-3">Showing <button className="header-link" onClick={() => this.changeSalesMetric()}>{displaySalesMetric(this.state.sales_metric)}</button></small>
              </Col>
              <Col xs={12} md={6} lg={6} xl={6}>
                <DashCam 
                  isLoaded={this.state.isLoaded} 
                  print={this.props.print} 
                  results={this.state.results} 
                  lookback={this.state.lookback} 
                  payload={this.state.dashCamData}
                />
              </Col>
            </Row>
          ) : null}

          <More className="gutter-right-fixed-2" url="/app/transactions" tab={0} perf_date={this.state.perf_date} />
          
        </Widget>

      ) : (
        <Widget title={
          <div className="d-flex justify-content-between flex-wrap">
            <h5><span className="text-danger">Sales KPIs</span> <small className="text-muted">{this.state.dateRangeDisplay ? (<span>from {this.state.dateRangeDisplay} .&nbsp;</span>) : null}Charts are displayed by <button className="header-link" onClick={() => this.changeChartTimespan()}>{this.state.chart_timespan === 'days' ? ('day') : ('week')}</button>.</small></h5>
            {this.state ? (
              <Select
                value={this.state.perf_trans_selected}
                onChange={(event) => changePT(event) }
                options={this.state.perf_trans_options}
                placeholder='Filter results...'
                isMulti={false}
                className="form-control-sm"
              /> 
            ) : null}
          </div>
        }>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <>

              <ChartLegend print={this.props.print} years={this.state.fixed_years} className="gutter-top-fixed-4" lookback={this.state.lookback} />

              <Row className="gutter-top-fixed-2 gutter-bottom-fixed-2">
                <DataPointCard print={this.props.print} results={this.state.results} title="QTY" field="qty" lookback={this.state.lookback} format="X," timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Face Value" field="face" lookback={this.state.lookback} format="$X" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Service Fees" field="fees" lookback={this.state.lookback} format="$X" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Avg Price" lookback={this.state.lookback} format="$X.XX" field="average_price" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Margin" lookback={this.state.lookback} format="X.X%" field="margin" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Avg Fixed Cost" parent="per_ticket" field="fixed_cost" lookback={this.state.lookback} format="$X.XX" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Avg Order Break-Even" parent="per_order" field="net" lookback={this.state.lookback} format="$X.XX" timespan={this.state.chart_timespan} lg={this.state.col_lg} />

                {this.props.user && this.props.user.admin ? (
                  <DataPointCard print={this.props.print} results={this.state.results} title="Avg Access Fee" field="access_fees_per_ticket" lookback={this.state.lookback} format="$X.XX" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                ): null}
                {this.props.user && this.props.user.admin ? (
                  <DataPointCard print={this.props.print} results={this.state.results} title="Affiliate Fees" field="affiliate_commission" lookback={this.state.lookback} format="$X" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                ): null}

              </Row>

            </>
          ) : null}
        </Widget>
      )
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetSalesTimespans));
