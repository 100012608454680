import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  Nav,
  NavItem,
  Button
} from 'reactstrap';

import WidgetShows from '../../widgets/access/Shows';

import isScreen from '../../core/screenHelper';

import s from './Access.module.scss';

class Access extends React.Component {

  constructor(props) {
    super(props);

    let perf_date = false;
    let tabSelected = 0;
    let q = props.location.state;
    if(q && q.tab) { 
      tabSelected = q.tab; 
    } else {
      if(this.props.match.params.tab) {
        tabSelected = parseInt(this.props.match.params.tab);
      }
    }
    if(q && q.perf_date) { perf_date = q.perf_date; }

    let baseUrl = '/#/app/access/';
    let baseTitle = 'Access Fees';

    this.state = {
      isLoaded: false,
      tabSelected: tabSelected,
      lead_groups: [
        '0-0,1-3,4-6,7-13,14-20,21-27,28-34,35-41,42-48,49-55,56-62,63-69,70-76,77-83,84-1000',
        '0-28,29-55,56-1000'
      ],
      price_groups: [
        '0-59.99,60-79.99,80-99.99,100-129.99,130-159.99,160-189.99,190-229.00,230-999.99'
      ],
      perf_date: perf_date,
      base_url: baseUrl,
      title: baseTitle
    };

    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.setTabs();
  };

  setTabs() {

    let tabs = [];

    if(this.props.user.permissions.pages.transactions.tabs.shows) {
      tabs.push({
        id: 0,
        icon: 'fa-star',
        name: 'Model Analyzer'
      });
    }
    
    this.setState({
      tabs: tabs
    });

  }

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  render() {
  
    return (
      <div className={s.root}>

        {this.state ? (
          <Helmet>
            <title>SH4: {this.state.title}</title>
          </Helmet>
        ) : null}
        
        <div className="menuContainer lg">
          <Nav className="mt-4">
            <NavItem className="nopad-bottom nomargin-bottom">
                <h5 className="text-danger">Access Fees</h5>
                {this.state.tabs ? (
                  this.state.tabs.map((tab, i) =>
                    <a key={i} href={this.state.base_url + tab.id} className="">
                      <Button
                        color={this.state.tabSelected === tab.id ? 'danger' : 'default'} className="mr-xs"
                        active={this.state.tabSelected === tab.id}
                      ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === tab.id ? 'text-default' : 'text-danger'].join(' ')} />
                      <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                    </a>
                  )
                ) : null}
            </NavItem>
          </Nav>
        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

          {this.state.tabSelected === 0 ? (
            <div>
              <WidgetShows sales_metric="face" affiliate={false} display={1000} user={this.props.user} />
            </div>
          ): null}
        
        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Access));
