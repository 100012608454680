import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { nFormatter, formatNumber } from '../../../../core/utils';
import Poster from '../../../../components/Poster';

import { Table } from 'reactstrap';

class WidgetKnownProductCard extends React.Component {

  static propTypes = {
    product: PropTypes.object,
    print: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  static defaultProps = {
    print: false,
    width: 300,
    height: 100,
  };

  constructor(props) {
    super(props);

    this.state = {
      chart: {
        data: []
      }
    }

    this.setChart = this.setChart.bind(this);
    this.salesSummaryDataFormat = this.salesSummaryDataFormat.bind(this);
    this.salesSummaryDateFormat = this.salesSummaryDateFormat.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
  }

  componentDidMount() {
    if(this.props.product.trend) {
      this.setChart();
    }
  }

  setChart() {

    var chartData = [];

    this.props.product.trend.forEach(function(block, i) {
      
      var d = {
        week: block.dates.end,
        sales: block.sales.fees
      };

      if(block.sales.fees > 0) {
        chartData.push(d);
      }

    });
    
    var aboveMax = 1.1;
    this.setState({
      chart: {
        data: chartData,
        properties: {
          aboveMax: aboveMax
        }
      }
    });
  }

  salesSummaryDataFormat(x) {
    return '$' + nFormatter(x);
  };

  salesSummaryDateFormat(x) {
    return moment(x).format('M/DD');
  };

  selectProduct = (e, id) => {
    e.preventDefault();
    this.props.history.push('/app/products/' + id);
  }

  render() {

    return (

      <div>

        <div className="card gutter-bottom-fixed-4">
          <div className="card-body nopad nomargin">

            {this.props.product.product ? (
              <div className="d-flex">
                <div>
                  <a href="/" onClick={((e) => this.selectProduct(e, this.props.product.product.id))}>
                    <Poster id={this.props.product.product.poster_id} w={95} className="border-right img-poster" style={{width: 105}} />
                  </a>
                </div>
                <div className="flex-grow">
                    <Table className="fs-mini bordered nopad-bottom nomargin-bottom border p-0 m-0 bg-light">
                      <tbody className="p-1"> 
                        <tr>
                          <td className="" colSpan={2}>
                            <a href="/" onClick={((e) => this.selectProduct(e, this.props.product.product.id))} className="fw-bold">
                              {this.props.product.product.short_name ? (
                                <span>{this.props.product.product.short_name}</span>
                              ) : (
                                this.props.product.product.name && (this.props.product.product.name.length > 40) ? (
                                  <span>{this.props.product.product.name.slice(0,40)}...</span>
                                ) : (
                                  <span>{this.props.product.product.name}</span>
                                )
                              )}
                            </a>
                          </td>
                          <td width="30%">
                            <span>Face Value</span>
                          </td>
                          <td width="20%">
                            <span>{formatNumber('$X', this.props.product.face, false)}</span>
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">
                            <span>Average Margin</span>
                          </td>
                          <td width="20%">
                            <span>{formatNumber('X.X%', this.props.product.margin, false)}</span>
                          </td>
                          <td width="30%">
                            <span>Service Fees</span>
                          </td>
                          <td width="20%">
                            <span>{formatNumber('$X', this.props.product.fees, false)}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>Margin</span>
                          </td>
                          <td>
                            <span>{formatNumber('X.X%', this.props.product.per_order.margin, false)}</span>
                          </td>
                          <td>
                            <span>Fixed Cost</span>
                          </td>
                          <td className="">
                            <span>{formatNumber('$X', this.props.product.fixed_cost, false)}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>Average Ticket Price</span>
                          </td>
                          <td>
                            <span>{formatNumber('$X.XX', this.props.product.average_price, false)}</span>
                          </td>
                          <td>
                            <span className="fw-bold">Net Profit</span>
                          </td>
                          <td className="">
                            <span className="fw-bold">{formatNumber('$X', this.props.product.net_net, false)}</span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
{/* 
                    <div>
                      <h5>{formatNumber('$X.XX', this.props.product.per_order.net, false)}</h5>
                      <p className="m-0 p-0 gutter-top-fixed-1 text-muted">Margin: {formatNumber('X.X%', (1-(this.props.product.per_order.fixed_cost/this.props.product.per_order.fees)), false)}</p>
                    </div> */}
                </div>
              </div>
            ) : null}

          </div>
        </div>
                          
      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetKnownProductCard));
