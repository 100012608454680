import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';

import Select from 'react-select';

import { Row, Col } from 'reactstrap';

import { scurl, filterUpdated } from '../../../core/utils.js';

import Loading from '../../../components/Loading';

import CampaignCard from './cards/Campaign.js';

class WidgetSemCampaigns extends React.Component {

  static propTypes = {
    user: PropTypes.object,
    className: PropTypes.string,
    type: PropTypes.string
  };

  static defaultProps = {
    className: '',
    type: 'generic'
  }

  constructor(props) {
    super(props); 

    let items = [
      { value: '', label: '----' },
      { value: 'channel:Google', label: 'Channel: Google' },
      { value: 'channel:Bing', label: 'Channel: Bing' }
    ];

    let sem_filter = {
      items: items,
      isLoaded: true
    }

    this.state = {
      isLoaded: false,
      sem_filter: sem_filter,
      sem_filter_string: '',
    };

    this._isMounted = false;

    this.getCampaigns = this.getCampaigns.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.getCampaigns();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'SEM CAMPAIGNS WIDGET')) {
      this.getCampaigns();
    } else {
      if(prevProps.sem_filter !== this.props.sem_filter) {
        this.getCampaigns();
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  getCampaigns() {

    var opts = {
      path: '/v2/sem/campaigns',
      type: 'universal',
      years: 2
    };

    if(this.state.sem_filter_string.length > 0) {
      opts.sem_filter = this.state.sem_filter_string;
    }

    //console.log(opts);
    this.setState({
      isLoaded: false,
      results: []
    }, function() {
      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {
              let filtered = [];
              if(this.props.type === 'shows') {
                for(var campaign of results.campaigns) {
                  if(campaign.breadcrumbs && (campaign.breadcrumbs.type === 'shows')) {
                    if((campaign.years[0].campaign_data.spend > 0) && (campaign.years[0].campaign_data.orders > 0)) {
                      filtered.push(campaign);
                    }
                  }
                }
              } else {
                for(var show_campaign of results.campaigns) {
                  if(show_campaign.breadcrumbs && (show_campaign.breadcrumbs.type !== 'shows')) {
                    filtered.push(show_campaign);
                  }
                }
              }


              this.setState({
                isLoaded: true,
                results: {
                  campaigns: filtered
                }
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    }.bind(this));
  }

  handleFilterChange = selectedOption => {

    var hasChanged = true;

    let sem_filter_string = '';
    for(var [i,selected] of selectedOption.entries()) {
      if(i > 0) {
        sem_filter_string += ',';
      }
      sem_filter_string += selected.value;
    }

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      sem_filter: {
        ...this.state.sem_filter,
        selected: selectedOption
      },
      sem_filter_string: sem_filter_string
    }, function() {
      this.getCampaigns();
    });
  };

  render() {
    return (

      <Widget title={
        <Row>
          <Col xs={12} sm={8} md={8} lg={9} xl={9}>
            <div className="d-flex justify-content-between flex-wrap">
              <h5 className="text-danger">SEM Campaigns<small className="text-muted pl-2">KPIs by campaigns, sorted in descending order by search spend.</small></h5>
            </div>
          </Col>
          <Col xs={12} sm={5} md={4} lg={3} xl={3}>
            <Select
              value={this.state.sem_filter.selected}
              onChange={this.handleFilterChange}
              options={this.state.sem_filter.items}
              placeholder='Filter...'
              isMulti={true}
            /> 
          </Col>
        </Row>
      }>
        
        <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />
        
        {this.state.isLoaded ? (

          <div className="gutter-top-fixed-2 gutter-bottom-fixed-2">

            <Row className="gutter-top-fixed-2">
              <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                <p className="m-0 p-0"><br/><span className="">CAMPAIGN NAME</span></p>
              </Col>
              <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
                <p className="m-0 p-0">SEARCH SPEND TO<br/>GENERATE ORDER</p>
              </Col>
              <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
                <p className="m-0 p-0">SERVICE FEES PER<br/>GENERATED ORDER</p>
              </Col>
              <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
                <p className="m-0 p-0">GROSS PROFIT PER<br/>GENERATED ORDER</p>
              </Col>
              <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
                <p className="m-0 p-0">PROFIT PER ORDER<br/>AFTER SEARCH SPEND</p>
              </Col>
            </Row>

            <hr/>

            {this.state.results.campaigns.map((campaign, i) =>
              <div key={i}>
                <CampaignCard key={i} print={this.props.print} campaign={campaign} user={this.props.user} />
              </div>
            )}
          
          </div>
          
        ) : null}

      </Widget>
  )}

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetSemCampaigns));
