import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import More from '../../../components/More';

import { Row, Col, Table } from 'reactstrap';

import { scurl } from '../../../core/utils.js';
import { filterUpdated, datespan, dateRangeDisplay } from '../../../core/utils';

import Displays from '../../../components/Displays';

import CBar from '../../charts/Bar.js';

class WidgetMargin extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    dashboard: PropTypes.bool,
    timespan: PropTypes.string,
    fixed_years: PropTypes.number,
    lookback: PropTypes.number,
    product_id: PropTypes.number,
    lead_groups: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    print: false,
    dashboard: false,
    timespan: 'days',
    fixed_years: 2,
    lookback: 30,
    className: ''
  };

  constructor(props) {
    super(props);

    let drd = dateRangeDisplay(this.props.filter.dates, 'MMMM dd, yyyy');
    
    let chart_aspect_width = 5;
    let chart_aspect_height = 1;

    if(this.props.dashboard) {
      chart_aspect_width = 1.5;
      chart_aspect_height = 1;
    }

    this.state = {
      isLoaded: false,
      isError: false,
      fixed_years: this.props.fixed_years,
      print: this.props.print,
      dateRangeDisplay: drd,
      chart_aspect_width: chart_aspect_width,
      chart_aspect_height: chart_aspect_height,
    }

    this._isMounted = false;
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'LEAD TIMES WIDGET')) {
      this.loadData();
    } else {
      if(prevProps.lead_groups !== this.props.lead_groups) {
        this.loadData();
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadData() {

    this.setFilterText();
    
    var lookback = datespan(this.props.filter);
    if(lookback < 7) {
      //lookback = 7;
    }
    this.setState({
      isLoaded: false,
      results: [],
      lookback: parseInt(lookback)
    }, function() {

      var opts = {
        path: '/v2/transactions/margin',
        type: 'universal',
        years: this.props.fixed_years,
        // lookback: this.state.lookback
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {
              this.setState({
                isLoaded: true,
                results: results,
              }, function() {
                //console.log(this.state.results)
                this.setChartData();
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  };

  setChartData() {
    
    if(this.state.results && this.state.results.years[0].types && this.state.results.years[0].types.length > 0) {

      //BAR CHART
      let bar_sales = [];

      let x = 0;
      for(var block of this.state.results.years[0].types) {

        if(x > 0) {
          let datapoint = {
            name: block.margin_type,
            cy: block.qty
          };

          if(this.state.results.years[1].types[x]) {
            datapoint.y2 = this.state.results.years[1].types[x].qty
          }

          bar_sales.push(datapoint);
        }

        x++;
      }

      var bar_min = 0.85;
      var bar_max = 1.15;

      this.setState({
        bar_sales: {
          data: bar_sales,
          properties: {
            max: bar_max,
            min: bar_min
          }
        }
      });
    }
  }

  setFilterText() {
    this.setState({
      ...this.state,
      filterText: this.props.lead_groups
    });
  };

  render() {

    var title = <h5>Title</h5>
    this.props.dashboard ? (
      title = <h5>
        <span>Serv Fee Margin</span>
        <small className="text-muted pl-2">vs prior year</small>
      </h5>
    ) : (
      title = <h5><span className="text-danger">Margin Types</span> <small className="text-muted">{this.state.dateRangeDisplay ? (<span>Transaction date sales data for {this.state.dateRangeDisplay} .&nbsp;</span>) : null}</small></h5>
    )

    return (

      this.props.dashboard ? (

        <Widget title={title} className="mb-0 h-100" bodyClass={`mt p-4`}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            this.state.isLoaded && this.state.bar_sales && this.state.bar_sales.data ? (
              <>
                <Row className="border-bottom gutter-top-fixed-1 gutter-bottom-fixed-2">
                  <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                    <h4 className="fw-semi-bold gutter-top-fixed-1">{parseFloat(this.state.results.years[0].types[0].margin*100).toFixed(1)}% <span className="fw-normal text-muted fs-mini">overall margin</span></h4>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={4} xl={4} className="text-center">
                    <h6 className="text-muted gutter-top-fixed-2"><Displays a={this.state.results.years[0].types[0].margin} b={this.state.results.years[1].types[0].margin} format="percent" /></h6>
                  </Col>
                </Row>

                <CBar
                  data={this.state.bar_sales.data}
                  print={this.state.print}
                  chart_aspect_width={this.state.chart_aspect_width}
                  chart_aspect_height={this.state.chart_aspect_height}
                  labels={false}
                  grid={false}
                />
              </>
            ) : null
          ) : null}

          <More url="/app/transactions/5" />
          
        </Widget>

      ) : (
        <div className={this.props.className}>

          <Widget title={title}>

            <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

                <>
                  {this.state.isLoaded ? (
                    <>
                    {this.state.bar_sales && this.state.bar_sales.data ? (
                      <div>
                        <CBar
                          data={this.state.bar_sales.data}
                          print={this.state.print}
                          chart_aspect_width={this.state.chart_aspect_width}
                          chart_aspect_height={this.state.chart_aspect_height}
                          labels={true}
                        />
                      </div>
                    ) : null}

                    <div className="page-divide"></div>

                    <Row>

                      {this.state.results.years[0].types.map((type, i) =>
                        <Col xs={12} sm={6} md={6} lg={3} xl={3} key={i} >
                          <h6 className="gutter-top-fixed-3">{type.margin_type}</h6>
                          <Table className="fs-mini nopad-bottom nomargin-bottom border bg-light">
                            <thead>
                              <tr>
                                  <th width="33%">Metric</th>
                                  <th className="text-center" width="33%">This Year<br/><span className="fs-mini text-muted">Last Year</span></th>
                                  <th className="text-center" width="33%">vs PY</th>
                              </tr>
                            </thead>
                            <tbody> 
                              <tr>
                                <td>
                                  <h6>% of Orders</h6>
                                </td>
                                <td className="text-center">
                                  <h6>{parseFloat((type.orders/this.state.results.years[0].types[0].orders)*100).toFixed(1)}%</h6>
                                  {this.state.results.years[1].types[0].orders > 0 ? (
                                  <span className="fs-mini text-muted">{parseFloat((this.state.results.years[1].types[i].orders/this.state.results.years[1].types[0].orders)*100).toFixed(1)}%</span>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={type.orders/this.state.results.years[0].types[0].orders} b={this.state.results.years[1].types[i].orders/this.state.results.years[1].types[0].orders} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Qty</h6>
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={type.qty} format="comma" /></h6>
                                  {this.state.results.years[1].types[0].orders > 0 ? (
                                  <span className="fs-mini text-muted"><Displays a={this.state.results.years[1].types[i].qty} format="comma" /></span>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={type.qty} b={this.state.results.years[1].types[i].qty} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Avg Price</h6>
                                </td>
                                <td className="text-center">
                                  <h6>${parseFloat(type.average_price).toFixed(2)}</h6>
                                  {this.state.results.years[1].types[0].orders > 0 ? (
                                  <span className="fs-mini text-muted">${parseFloat(this.state.results.years[1].types[i].average_price).toFixed(2)}</span>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={type.average_price} b={this.state.results.years[1].types[i].average_price} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Margin</h6>
                                </td>
                                <td className="text-center">
                                  <h6>{parseFloat(type.margin*100).toFixed(1)}%</h6>
                                  {this.state.results.years[1].types[0].orders > 0 ? (
                                  <span className="fs-mini text-muted">{parseFloat(this.state.results.years[1].types[i].margin*100).toFixed(1)}%</span>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={type.margin} b={this.state.results.years[1].types[i].margin} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Fees</h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<Displays a={type.fees} format="comma" /></h6>
                                  {this.state.results.years[1].types[0].orders > 0 ? (
                                  <span className="fs-mini text-muted">$<Displays a={this.state.results.years[1].types[i].fees} format="comma" /></span>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={type.fees} b={this.state.results.years[1].types[i].fees} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Access &<br/>CC Fees</h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<Displays a={(type.access_fees + type.cc_fees)} format="comma" /></h6>
                                  {this.state.results.years[1].types[0].orders > 0 ? (
                                  <span className="fs-mini text-muted">$<Displays a={this.state.results.years[1].types[i].access_fees + this.state.results.years[1].types[i].access_fees} format="comma" /></span>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={(type.access_fees + type.cc_fees)} b={this.state.results.years[1].types[i].access_fees + this.state.results.years[1].types[i].access_fees} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Affiliate Fees</h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<Displays a={type.affiliate_commission} format="comma" /></h6>
                                  {this.state.results.years[1].types[0].orders > 0 ? (
                                  <span className="fs-mini text-muted">$<Displays a={this.state.results.years[1].types[i].affiliate_commission} format="comma" /></span>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={type.affiliate_commission} b={this.state.results.years[1].types[i].affiliate_commission} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Net</h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<Displays a={type.net_net} format="comma" /></h6>
                                  {this.state.results.years[1].types[0].orders > 0 ? (
                                  <span className="fs-mini text-muted">$<Displays a={this.state.results.years[1].types[i].net_net} format="comma" /></span>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={type.net_net} b={this.state.results.years[1].types[i].net_net} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Net Per Ticket</h6>
                                </td>
                                <td className="text-center">
                                  <h6 className="fw-bold">${parseFloat(type.net_net/type.qty).toFixed(2)}</h6>
                                  {this.state.results.years[1].types[0].orders > 0 ? (
                                  <span className="fs-mini text-muted">${parseFloat(this.state.results.years[1].types[i].net_net/this.state.results.years[1].types[i].qty).toFixed(2)}</span>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={type.net_net/type.qty} b={this.state.results.years[1].types[i].net_net/this.state.results.years[1].types[i].qty} format="percent" /></h6>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                    )}
                    </Row>
                  </>
                  ) : null }
                </>
              </Widget>

        </div>

      )
    )
  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetMargin));
