import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';

import { scurl } from '../../../core/utils.js';
import { filterUpdated, defaultTimespanV2, datespan, dateRangeDisplay } from '../../../core/utils';

import DataPointCard from '../../cards/DataPoint.js';
import ChartLegend from '../../cards/Legend.js';

import DashCam from '../../universal/DashCam/DashCam.js';

class WidgetPipeline extends React.Component {

  static propTypes = {
    user: PropTypes.object,
    print: PropTypes.bool,
    dashboard: PropTypes.bool,
    timespan: PropTypes.string,
    fixed_years: PropTypes.number,
    lookback: PropTypes.number,
    product_id: PropTypes.number,
    skai_filter: PropTypes.string,
  };

  static defaultProps = {
    print: false,
    dashboard: false,
    timespan: 'days',
    fixed_years: 2,
    lookback: 30,
    skai_filter: ''
  };

  constructor(props) {
    super(props);

    let dorw = defaultTimespanV2(this.props.filter);
    let drd = dateRangeDisplay(this.props.filter.dates, 'MMMM dd, yyyy');
    
    this.state = {
      isLoaded: false,
      isError: false,
      chart_timespan: dorw,
      fixed_years: this.props.fixed_years,
      print: this.props.print,
      dateRangeDisplay: drd,
      dashCamData: {},
      isFiltered: false
    }

    this._isMounted = false;
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'CONVERSIONS WIDGET')) {
      this.loadData();
    } else {
      if(prevProps.skai_filter !== this.props.skai_filter) {
        this.loadData();
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadData() {

    this.setFilterText();
    
    var lookback = datespan(this.props.filter);
    if(lookback < 7) {
      //lookback = 7;
    }
    this.setState({
      isLoaded: false,
      results: [],
      //lookback: parseInt(lookback)
    }, function() {

      var opts = {
        path: '/v2/sem/timespans',
        type: 'universal',
        years: this.props.fixed_years,
        //lookback: this.state.lookback
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      if(this.props.skai_filter.length > 0) {
        opts.skai_filter = this.props.skai_filter;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {

              let isFiltered = false;
              if(this.props.skai_filter.length > 0) {
                isFiltered = true;
              }

              this.setState({
                isLoaded: true,
                results: results,
                isFiltered: isFiltered
              }, function() {
                this.setDashCam();
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  changeChartTimespan = () => { 
    var change_display = this.state.chart_timespan;
    if(change_display === 'weeks') {
      change_display = 'days';
    } else {
      change_display = 'weeks';
    }
    this.setState({
      chart_timespan: change_display
    });
  }

  setFilterText() {
    this.setState({
      ...this.state,
      filterText: this.props.skai_filter
    });
  };

  setDashCam() {

    let dashCamData = {
      results: this.state.results,
      headline: {
        title: 'spent',
        value: this.state.results.totals[0].campaign_data.spend,
        format: '$X',
        yoy: parseFloat((this.state.results.totals[0].campaign_data.spend-this.state.results.totals[1].campaign_data.spend)/this.state.results.totals[1].campaign_data.spend)
      },
      panels: [
        {
          title: 'Impressions',
          value: this.state.results.totals[0].campaign_data.impressions,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0].campaign_data.impressions-this.state.results.totals[1].campaign_data.impressions)/this.state.results.totals[1].campaign_data.impressions)
        },
        {
          title: 'Visits',
          value: this.state.results.totals[0].campaign_data.clicks,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0].campaign_data.clicks-this.state.results.totals[1].campaign_data.clicks)/this.state.results.totals[1].campaign_data.clicks)
        },
        {
          title: 'Conversion %',
          value: this.state.results.totals[0].campaign_data.conversion_percentage,
          format: 'X.X%',
          yoy: parseFloat((this.state.results.totals[0].campaign_data.conversion_percentage-this.state.results.totals[1].campaign_data.conversion_percentage)/this.state.results.totals[1].campaign_data.conversion_percentage)
        },
        {
          title: 'Orders',
          value: this.state.results.totals[0].campaign_data.orders,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0].campaign_data.orders-this.state.results.totals[1].campaign_data.orders)/this.state.results.totals[1].campaign_data.orders)
        },
        {
          title: 'Fee ROI',
          value: this.state.results.totals[0].campaign_data.service_fee_roi,
          format: 'X.XX',
          yoy: parseFloat((this.state.results.totals[0].campaign_data.service_fee_roi-this.state.results.totals[1].campaign_data.service_fee_roi)/this.state.results.totals[1].campaign_data.service_fee_roi)
        },
        // {
        //   title: 'Net ROI',
        //   value: this.state.results.totals[0].campaign_data.net_roi,
        //   format: '$X.XX',
        //   yoy: parseFloat((this.state.results.totals[0].campaign_data.net_roi-this.state.results.totals[1].campaign_data.net_roi)/this.state.results.totals[1].campaign_data.net_roi)
        // }
      ]
    };

    this.setState({
      ...this.state,
      dashCamData: dashCamData
    });
  };

  render() {

    return (

      this.props.dashboard ? (
        <Widget 
          title={
            <h5>
              <span>SEM</span>
              <small className="text-muted pl-2">vs prior year</small>
            </h5>
          } 
          className="mb-0 h-100" 
          bodyClass={`mt p-4`}
        >
          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />
          <DashCam 
            isLoaded={this.state.isLoaded} 
            print={this.props.print} 
            results={this.state.results} 
            title="Search" 
            description="versus prev year" 
            lookback={this.state.lookback} 
            payload={this.state.dashCamData}
            more={{url: 'search', tab: 0}}
          />
        </Widget>
      ) : (

        <div className="page-divide">

          <Widget title={
            <Row>
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <h5>
                  <span className="text-danger">KPIs</span> <small className="text-muted">{this.state.dateRangeDisplay ? (<span>From {this.state.dateRangeDisplay} .&nbsp;</span>) : null}Charts are displayed by <button className="header-link" onClick={() => this.changeChartTimespan()}>{this.state.chart_timespan === 'days' ? ('day') : ('week')}</button>.</small>
                </h5>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                {this.state.isLoaded ? (
                  <ChartLegend print={this.props.print} years={this.state.fixed_years} className="float-right" lookback={this.state.lookback} />
                ) : null}
              </Col>
            </Row>
          }>

            <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

            {this.state.isLoaded ? (
              <>

                {!this.state.isFiltered ? (
                  <>
                    <h5 className="gutter-top-fixed-4 text-warning text-center">PER ORDER METRICS FOR <u>ALL ORDERS</u></h5>
                    <Row className="gutter-bottom-fixed-2">
                      <DataPointCard print={this.props.print} results={this.state.results} title="Fees Per Order" lookback={this.state.lookback} format="$X.XX" parent="transactions" field="service_fees_per_order" timespan={this.state.chart_timespan} />
                      <DataPointCard print={this.props.print} results={this.state.results} title="Gross Profit Per Order" lookback={this.state.lookback} format="$X.XX" parent="transactions" field="net_net_per_order" timespan={this.state.chart_timespan} />
                      <DataPointCard print={this.props.print} results={this.state.results} title="Search Spend Per Order" lookback={this.state.lookback} format="$X.XX" parent="transactions" field="cost_per_order" timespan={this.state.chart_timespan} />
                      <DataPointCard print={this.props.print} results={this.state.results} title="Search Profit Per Order" lookback={this.state.lookback} format="$X.XX" parent="transactions" field="net_profit_per_order" points={2} timespan={this.state.chart_timespan} />
                    </Row>

                    <hr/>
                  </>
                ) : null}

                <h5 className="gutter-top-fixed-2 text-warning text-center">METRICS REPORTED BY GOOGLE/BING FOR <u>SEARCH ORDERS</u></h5>
                <Row>
                  <DataPointCard print={this.props.print} results={this.state.results} title="Spend" lookback={this.state.lookback} format="$XM" parent="campaign_data" field="spend" timespan={this.state.chart_timespan} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Cost Per Order" lookback={this.state.lookback} format="$X.XX" parent="campaign_data" field="cost_per_order" timespan={this.state.chart_timespan} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Service Fees ROI" lookback={this.state.lookback} format="X.XX" parent="campaign_data" field="service_fee_roi" points={2} timespan={this.state.chart_timespan} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Cost Per Click" lookback={this.state.lookback} format="$X.XX" parent="campaign_data" field="cpc" timespan={this.state.chart_timespan} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Conversion %" lookback={this.state.lookback} format="X.XX%" parent="campaign_data" field="conversion_percentage" timespan={this.state.chart_timespan} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Service Fees" lookback={this.state.lookback} format="$XM" parent="campaign_data" field="service_fees" timespan={this.state.chart_timespan} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Impressions" lookback={this.state.lookback} format="XM" parent="campaign_data" field="impressions" timespan={this.state.chart_timespan} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Click-Through %" lookback={this.state.lookback} format="X.X%" parent="campaign_data" field="ctr" timespan={this.state.chart_timespan} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Total Visits" lookback={this.state.lookback} format="XM" parent="campaign_data" field="clicks" timespan={this.state.chart_timespan} />
                </Row>

              </>
            ) : null}
          </Widget>


        </div>

      )
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetPipeline));
