import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import Displays from '../../../components/Displays';
import { scurl } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';

import { Row, Col, Table, Button, Form, FormGroup, Input, Label } from 'reactstrap';

import moment from 'moment';

class WidgetBwFlash extends React.Component {

  static propTypes = {
    perf_date: PropTypes.bool,
    fixed: PropTypes.bool,
    className: PropTypes.string
  };

  static defaultProps = {
    perf_date: true,
    fixed: false,
    className: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      perf_date: this.props.perf_date
    }

    this._isMounted = false;
    this.loadSales = this.loadSales.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadSales();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'EXPORT WIDGET')) {
      this.loadSales();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadSales() {
    this.setState({
      isLoaded: false,
      results: [],
      error: null
    }, function() {

      var opts = {
        path: '/v2/flash',
        type: 'universal'
      };

      if(this.props.fixed) {
        opts.univ_start = moment().add(-1, 'week').startOf('week').add(1,'day').format('YYYY-MM-DD');
        opts.univ_end = moment().add(-1, 'week').endOf('week').add(1,'day').format('YYYY-MM-DD');
      }

      if(this.state.perf_date) {
        opts.perf_date = true;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            }, function() {
              this.setCopy();
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  changePerfTrans = () => { 
    this.setState({
      perf_date: !this.state.perf_date
    }, function() {
      this.loadSales();
    });
  }

  setCopy() {
    var copy = '';
    copy+= 'Qty: ' + this.state.results.transactions.totals[0].qty + '\r\n';
    copy+= 'Face: ' + parseFloat(this.state.results.transactions.totals[0].face).toFixed(2) + '\r\n';
    copy+= 'Fees: ' + parseFloat(this.state.results.transactions.totals[0].fees).toFixed(2) + '\r\n';
    copy+= 'Access: ' + parseFloat(this.state.results.transactions.totals[0].access_fees).toFixed(2) + '\r\n';
    copy+= 'CC Fees: ' + parseFloat(this.state.results.transactions.totals[0].cc_fees).toFixed(2) + '\r\n';
    copy+= 'Net: ' + parseFloat((this.state.results.transactions.totals[0].fees+this.state.results.transactions.totals[0].insurance)-this.state.results.transactions.totals[0].access_fees-this.state.results.transactions.totals[0].cc_fees).toFixed(2) + '\r\n';
    copy+= '\r\n';
    copy+= 'Affiliates: ' + parseFloat(this.state.results.transactions.totals[0].affiliate_commission).toFixed(2) + '\r\n';
    copy+= 'Search: ' + parseFloat(this.state.results.search.totals[0].campaign_data.spend).toFixed(2) + '\r\n';
    this.setState({
      copy: copy
    });
  }

  render() {

    var title = <h5><span className="text-danger">Sales Recap</span>&nbsp;&nbsp;<small>View sales data by <button className="header-link" onClick={() => this.changePerfTrans()}>{this.state.perf_date ? (<span>Performance Date</span>) : (<span>Transaction Date</span>)}</button>.</small></h5>;

    return (

      <div>

        <Widget title={title} className={this.props.className}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <div className="gutter-top-fixed-4">
              {this.state.results ? (
                <Row>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div>
                      <h5 className="text-left">{moment(this.state.results.transactions.dates[0].start).format('dddd, MMMM Do YYYY')} through {moment(this.state.results.transactions.dates[0].end).format('dddd, MMMM Do YYYY')}</h5>
                      {this.state.perf_date ? (
                        <h6 className="text-left">Performance Date</h6>
                      ) : (
                        <h6 className="text-left">Transaction Date</h6>
                      )}


                      <h5 className="gutter-top-fixed-4">TRANSACTIONS</h5>
                      <Table className="gutter-top-fixed-2">
                        <thead>
                          <tr>
                            <th width="30%">Metric</th>
                            <th width="70%">Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Qty</td>
                            <td><Displays a={parseInt(this.state.results.transactions.totals[0].qty)} format="comma" /></td>
                          </tr>
                          <tr>
                            <td>Face</td>
                            <td><Displays a={parseFloat(this.state.results.transactions.totals[0].face).toFixed(2)} format="comma" fixed={2} /></td>
                          </tr>
                          <tr>
                            <td>Serv Fees</td>
                            <td><Displays a={parseFloat(this.state.results.transactions.totals[0].fees).toFixed(2)} format="comma" fixed={2} /></td>
                          </tr>
                          <tr>
                            <td>Access Fees</td>
                            <td><Displays a={parseFloat(this.state.results.transactions.totals[0].access_fees).toFixed(2)} format="comma" fixed={2} /></td>
                          </tr>
                          <tr>
                            <td>Credit Card Fees</td>
                            <td><Displays a={parseFloat(this.state.results.transactions.totals[0].cc_fees).toFixed(2)} format="comma" fixed={2} /></td>
                          </tr>
                          <tr>
                            <td>Net Fees</td>
                            <td><Displays a={parseFloat((this.state.results.transactions.totals[0].fees+this.state.results.transactions.totals[0].insurance)-this.state.results.transactions.totals[0].access_fees-this.state.results.transactions.totals[0].cc_fees).toFixed(2)} format="comma" fixed={2} /></td>
                          </tr>
                        </tbody>
                      </Table>

                      <h5 className="gutter-top-fixed-4">OTHER</h5>
                      <Table className="gutter-top-fixed-2">
                        <thead>
                          <tr>
                            <th width="30%">Metric</th>
                            <th width="70%">Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Affiliate Commissions</td>
                            <td><Displays a={parseFloat(this.state.results.transactions.totals[0].affiliate_commission).toFixed(2)} format="comma" fixed={2} /></td>
                          </tr>
                          <tr>
                            <td>Paid Search Spend</td>
                            <td><Displays a={parseFloat(this.state.results.search.totals[0].campaign_data.spend).toFixed(2)} format="comma" fixed={2} /></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form>
                      <FormGroup>
                        <Label for="exampleText">Copy + Paste</Label>
                        <Input type="textarea" name="text" id="copy" defaultValue={this.state.copy} rows={14} />
                      </FormGroup>
                    </Form>
                    <div>
                        <Button className="btn-success text-white">
                          <i className="fa-light fa-copy pr-2" onClick={() => {navigator.clipboard.writeText(this.state.copy)}} />Copy To Clipboard
                        </Button>
                      </div>
                  </Col>
                </Row>
              ) : null}
            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetBwFlash));
